$background-white: #ffffff;
$background-grey: #fafafa;
$gradient-one: #da3394;
$gradient-two: #e03c67;
$gradient-three: #f3753b;
$gradient-four: #f99b4a;
$font-color-primary: #262626;
$font-color-secondary: #8e8e8e;
$font-color-footer: #c7c7c7;
$border-color-grey: #dbdbdb;
$border-color-grey-secondary: #efefef;
$link-color: #0095f6;
$link-color-secondary: #b3dffc;
$font: "Montserrat", sans-serif; // instead of Proxima Nova

// $background-white: #000000;
// $background-grey: #080808;
// $gradient-one: #da3394;
// $gradient-two: #e03c67;
// $gradient-three: #f3753b;
// $gradient-four: #f99b4a;
// $font-color-primary: #e2e2e2;
// $font-color-secondary: #8e8e8e;
// $font-color-footer: #2f2f2f;
// $border-color-grey: #2e2e2e;
// $border-color-grey-secondary: #111111;
// $link-color: #0095f6;
// $link-color-secondary: #b3dffc;
