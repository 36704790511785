.cardMenu {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;

  .interactions {
    display: flex;
    padding-left: 1em;
  }

  .icon {
    margin-right: 1em;
    width: 23px;
    height: 23px;

    &:hover {
      cursor: pointer;
    }
  }
}
