@import "/variables";

.card {
  border: 1px solid $border-color-grey;
  background-color: $background-white;
  margin-bottom: 1.5em;
  border-radius: 3px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75em;

    .profile {
      margin-bottom: 0;
    }

    .cardButton {
      height: 30px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .cardImage {
    width: 100%;
    display: block;
  }

  .likedBy {
    display: flex;
    padding-left: 1em;

    span {
      font-size: 0.75em;
      margin-left: 0.5em;
      padding-top: 0.5em;
    }
  }

  .comments {
    margin-bottom: 0.75em;
  }

  .timePosted {
    font-size: 0.6em;
    padding-left: 2em;
    padding-bottom: 1em;
    color: $font-color-secondary;
    border-bottom: 1px solid $border-color-grey-secondary;
  }

  .addComment {
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
    padding: 0.8em 1.25em;

    .commentText {
      color: $font-color-secondary;
    }

    .postText {
      color: $link-color-secondary;
    }
  }
}

@media only screen and (max-width: 640px) {
  .card {
    border: none;
    background-color: $background-grey;
    margin-bottom: 0.75em;

    .addComment {
      display: none;
    }

    .timePosted {
      border: none;
    }
  }
}
