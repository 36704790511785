@import "/variables";

.suggestions {
  margin-top: 1.25em;

  .titleContainer {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    font-weight: 500;
    color: $font-color-secondary;
    margin-bottom: 1.25em;

    .title {
      min-width: 200px;
    }

    a {
      width: 100%;
      text-align: right;
      color: black;
    }
  }
}
