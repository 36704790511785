@import "/variables";

.navigation {
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: $background-white;
  width: 100%;
  border-bottom: 1px solid $border-color-grey;

  .container {
    padding: 1em 0 0.9em 0;
    display: flex;
    align-content: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;

    .logo {
      padding: 0 1em;
      width: 100px;

      &:hover {
        cursor: pointer;
      }
    }

    .search {
      border: 1px solid $border-color-grey;
      border-radius: 10px;
      margin-left: 1.1rem;
      background-color: $background-grey;
      min-width: 500px;
      display: flex;
      align-items: center;
      justify-content: center;

      .searchIcon {
        width: 10px;
        opacity: 0.4;
        margin-right: 0.3em;
      }

      .searchText {
        color: $font-color-secondary;
        font-size: 0.85em;
        font-weight: 300;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .search {
    display: none !important;
  }
}
