@import "/variables";

.commentContainer {
  display: flex;
  padding-left: 1.5em;
  font-size: 0.75em;
  margin-bottom: 0.4em;

  .accountName {
    font-weight: 600;
  }

  .comment {
    margin-left: 0.5em;
  }
}
