@import "/variables";

body {
  background-color: $background-white;
  font-family: $font;
  font-size: 16px;
  margin: 0;
  padding: 0;
  min-width: fit-content;
}

main {
  background-color: $background-grey;
  margin-top: 45px;
  margin-left: 35px;

  .container {
    display: flex;
    justify-content: left;
    margin: 0 auto;
    max-width: 1000px;
  }
}

a {
  text-decoration: none;
  color: $link-color;
  font-size: 0.9em;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  main .container {
    justify-content: center !important;
  }
}
