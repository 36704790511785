.sidebar {
  width: 320px;
  padding: 6em 2em 0 2em;
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
