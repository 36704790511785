@import "/variables";

.storyBorder {
  padding: 2px;
  background: linear-gradient(
    200deg,
    $gradient-one 25%,
    $gradient-two 50%,
    $gradient-three 75%,
    $gradient-four 100%
  );
  border-radius: 50%;
}

.profileIcon {
  border-radius: 50%;
  border: 2px solid $background-white;
  display: block;

  &:hover {
    cursor: pointer;
  }

  &.small {
    width: 25px;
    height: 25px;
  }

  &.medium {
    width: 35px;
    height: 35px;
  }

  &.big {
    width: 60px;
    height: 60px;
  }
}
